<template>
  <v-calendar
    :disabled-dates="disabledDates"
    :attributes="calendarAttributes"
    :min-date="minDate"
    :max-date="maxDate"
    :timezone="timezone"
    @dayfocusin="onDayFocusIn"
    @mousedown.native="onCalendarMouseDown"
    @mouseup.native="onCalendarMouseUp"
    @daymouseenter="onDayMouseEnter"
    @dayclick="onDayClick"
    :locale="locale.value"
    @update:pages="handleMonthUpdate($event)"
    ref="calendarRef"
    expanded
  />
</template>

<script setup>
import { addDays, format, min, max, differenceInDays, addMinutes } from 'date-fns'

const props = defineProps({
  modelValue: [Array, Date],
  disabledDates: Array,
  minDate: Date,
  maxDate: Date,
  allowRange: { type: Boolean, default: false },
  isMultiple: { type: Boolean, default: false },
  attributes: { type: Array, default: () => [] },
  timezone: { type: String, default: 'utc' },
})

const { locale } = useI18n()
const emit = defineEmits(['input', 'update:modelValue', 'monthChange'])

const days = ref([])
const dragStartDay = ref(null)
const isCalendarDragging = ref(false)
const isMounted = ref(false)

const calendarRef = ref(null)

const dates = computed(() => days.value.map((day) => day.date))
let calendarAttributes = computed(() => {
  
  return [
  ...dates.value.map((date) => ({
    highlight: true,
    dates: date,
  })),
  ...props.attributes,
]})

let currentMonth = ref({
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
})
let previousMonth = ref({
  month: currentMonth.value.month,
  year: currentMonth.value.year,
})

const handleMonthUpdate = (newPage) => {
  const newPageValue = newPage[0]
  if (
    (newPageValue && previousMonth.value.month !== newPageValue.month) ||
    newPageValue.year !== previousMonth.value.year
  ) {
    emit('monthChange', newPageValue)
    previousMonth.value = { ...newPageValue }
  }
}

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    if (isMounted) {
      if (newValue) {
        if (Array.isArray(newValue)) {
          if (
            newValue.length !== oldValue.length ||
            newValue.some((date, index) => date !== oldValue[index])
          ) {
            days.value = newValue.map((date) => ({
              id: format(addMinutes(date, date.getTimezoneOffset()), 'yyyy-MM-dd'),
              date,
            }))
          }
        }
      }
    }
  }
)

watch(
  () => days.value,
  (value) => {
    if (props.isMultiple || props.allowRange) {
      dates.value.sort((a, b) => new Date(a) - new Date(b))
      emit('input', dates.value)
      emit('update:modelValue', dates.value)
    } else {
      emit('input', dates.value[0])
      emit('update:modelValue', dates.value[0])
    }
  },
  { deep: true }
)

onMounted(() => {
  if (props.modelValue) {
    if (
      (props.isMultiple || props.allowRange) &&
      Array.isArray(props.modelValue)
    ) {
      days.value.splice(
        0,
        0,
        ...props.modelValue.map((date) => ({
          id: format(addMinutes(date, date.getTimezoneOffset()), 'yyyy-MM-dd'),
          date,
        }))
      )
    } else if(props.modelValue instanceof Date) {
      days.value.push({
        id: format(addMinutes(props.modelValue, props.modelValue.getTimezoneOffset()), 'yyyy-MM-dd'),
        date: props.modelValue,
      })
    } else {
      days.value.push({
        id: format(props.modelValue, 'yyyy-MM-dd'),
        date: props.modelValue,
      })
    }
  }
  nextTick(() => (isMounted.value = true))
})

const move = (arg, opts) => {
  calendarRef.value.move(arg, opts)
}

const onCalendarMouseDown = () => {
  if (props.allowRange) {
    isCalendarDragging.value = true
  }
}

const onDayFocusIn = (day) => {
  if (props.allowRange) {
    dragStartDay.value = day
  }
}

const onCalendarMouseUp = () => {
  isCalendarDragging.value = false
}

const onDayMouseEnter = (day) => {
  if (props.allowRange && isCalendarDragging.value && !day.isDisabled && dragStartDay.value) {
    days.value.splice(0, days.value.length)

    const start = min([day.date, dragStartDay.value.startDate])
    const end = max([day.date, dragStartDay.value.endDate])
    const daysBetween = Math.abs(differenceInDays(end, start))
    days.value.push(dragStartDay.value)
    days.value.push(day)
    for (let i = 0; i < daysBetween; i++) {
      const incrementedDate = addDays(new Date(start), i)
      days.value.push({
        id: format(addDays(start, i), 'yyyy-MM-dd'),
        date: incrementedDate,
      })
    }
  }
}

const onDayClick = (day, event) => {
  if (!day.isDisabled) {
    if (props.isMultiple) {
      if (days.value.length > 1 || days.value[0].id !== day.id) {
        const idx = days.value.findIndex((d) => d.id === day.id)
        if (idx >= 0) {
          days.value.splice(idx, 1)
        } else {
          days.value.push({
            id: day.date,
            date: day.date,
          })
        }

        if (days.value.length > 0) {
          emit('update:modelValue', dates.value)
        }
      }
    } else if (props.allowRange && event.shiftKey && days.value.length === 1) {
      const startDay = days.value[0]
      days.value.splice(0, days.value.length)

      const start = min([day.date, startDay.date])
      const end = max([day.date, startDay.date])
      const daysBetween = Math.abs(differenceInDays(end, start))
      for (let i = 0; i <= daysBetween; i++) {
        const incrementedDate = addDays(new Date(start), i)
        days.value.push({
          id: format(incrementedDate, 'yyyy-MM-dd'),
          date: incrementedDate,
        })
      }
      emit('update:modelValue', dates.value)
    } else if (day.id != days.value[0]?.id) {
      days.value.splice(0, days.value.length)
      days.value.push({
        id: day.id,
        date: day.date,
      })
      emit('update:modelValue', dates.value)
    }
  }
}

defineOptions({
  name: 'CalendarPicker',
})
</script>

<style>
.vc-day {
  margin: 5px 0px !important;
}
.vc-bars {
  position: relative;
  top: 4px;
  width: 50% !important;
}
</style>
